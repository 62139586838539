
import { useEffect } from "react";
import posthog from "posthog-js";

export const usePostHogCapture = () => {
    useEffect(() => {
        if ((!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost'))) {
            posthog.init(process.env.REACT_APP_POST_HOG_KEY!!, { api_host: process.env.REACT_APP_POST_HOG_SERVER_URL, autocapture: false })
        }
    }, []);
}


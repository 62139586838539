import { ReactElement, useEffect, useState } from "react"
import {
    Bold10Text,
    Bold14Text,
    Bold7Text,
    Regular10Text,
    SemiBold10Text,
    SnapBadge,
    SnapEMISelector,
    SnapPrimaryButton,
} from "@snapmint/ui-components"
import { Plan } from "../../response/plan.res"
import { useUrlParamParser } from "../../page/util/url-param-parse.hook"
import { PlanService } from "../../service/plan.service"
import { getLocalStorageKey, setLocalStorageKey } from "../../utils/local-stoarge.helper"
import { useApp } from "../../app.hook"

interface PublicPlanSelectionComponentProps {
    onSelect: (plan: Plan, variableDp: any, isNewPlan:boolean) => void
    selectedPlan: Plan;
}

export const PublicPlanSelectionComponent = (props: PublicPlanSelectionComponentProps): ReactElement => {
    const { onSelect, selectedPlan } = props

    const transactionParamModel = useUrlParamParser()
    const { checkoutId } = transactionParamModel
    const [planList, setPlanList] = useState<Plan[]>([])
    const { divideCashBack, isCashBackUsed, cashbackConfig: {cashbackAmtApplied} } = useApp();
    const [variableDpOptionList, setVariableDpOptionList] = useState<any[]>([])

    const changePublicPlan = (planList: Plan[]) => {
        return planList?.map((_plan: Plan) => {
            if (_plan && !_plan.tdp && _plan?.tdp !== 0) {
                if (!_plan.tdp && _plan.dp) {
                    _plan.tdp = _plan.dp;
                }
                if (!_plan.emiAmount && _plan.emi) {
                    _plan.emiAmount = _plan.emi;
                }
                if (!_plan.emiStartDate && _plan.startAt) {
                    _plan.emiStartDate = _plan.startAt;
                    const endDate = new Date(_plan.startAt);
                    endDate.setMonth(endDate.getMonth() + _plan.tenure);
                    _plan.emiEndDate = endDate;
                }
            }
            return _plan;
        });
    };
    const fetchUserPlan = async (checkoutId: string, plan?: Plan, variableDp?: any) => {
        const planList: any = getLocalStorageKey(`public_plan_${checkoutId}`)
        const variableDpOptionList: any = getLocalStorageKey(`public_variableDpOptions_${checkoutId}`)
        if (planList && planList.length > 10 && !plan && !variableDp) {
            setPlanList(changePublicPlan(JSON.parse(planList)))
            if (variableDpOptionList && variableDpOptionList.length > 10) {
                setVariableDpOptionList(JSON.parse(variableDpOptionList))
            }
        } else {
            const res = await PlanService.getPublicPlan(checkoutId, { plan: plan, variableDp: variableDp })

            setLocalStorageKey(`public_plan_${checkoutId}`, JSON.stringify(res.data.plans))
            setPlanList(changePublicPlan(res.data.plans))
            if (res.data.variableDpOptions) {
                setLocalStorageKey(`public_variableDpOptions_${checkoutId}`, JSON.stringify(res.data.variableDpOptions))
                setVariableDpOptionList(res.data.variableDpOptions)
            }
        }
    }

    useEffect(() => {
        if (checkoutId) {
            fetchUserPlan(checkoutId)
        }
    }, [checkoutId])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        variableDpOptionList && variableDpOptionList?.forEach((variableDp: any) => {
            if (variableDp.dpAmount == e.target.value) {
                fetchUserPlan(checkoutId ? checkoutId : "", undefined, variableDp)
            }
        })
    };
    const { totalCashBack } = divideCashBack(selectedPlan?.tdp, selectedPlan?.emiAmount, selectedPlan?.tenure)

    return (
        <div className="flex flex-col">
            {variableDpOptionList && variableDpOptionList.length > 1 && (
                <div className="flex pb-[16px] mb-[16px] border-b-[1px] border-[#DADADA]">
                    <div className="input-hidden">
                        <SnapEMISelector itemList={variableDpOptionList.map((variableDpOption: any) => {
                            const { finalDp } = divideCashBack(variableDpOption.dpAmount)
                            return {
                                label: `${finalDp}`,
                                value: variableDpOption.dpAmount,
                                locked: !variableDpOption.isEligible,
                            }
                        })} name={"publicplanselector"} currentValue={variableDpOptionList.filter((variableDpOption: any) => {
                            return variableDpOption.default
                        })[0]?.dpAmount} title={"Select Downpayment"} onChange={handleChange} className="mb-[16px]" />
                    </div>
                </div>
            )}
            <div className="flex justify-between pb-[18px] pt-4">
                <Bold14Text text="Select EMI Tenure" className="text-[#000000]" />
                {isCashBackUsed && (
                        <div className="flex px-4 py-[3px] h-[16px] mt-[7px] items-center gap-[2px] bg-emiTab rounded-full">
                        <img className="w-[10px]" src="/assets/images/tumbs-up-2.svg" />{' '}
                        <p className="text-headGreen text-[9px] font-robotoBold"> ₹{totalCashBack} Cashback Applied</p>
                    </div>
                    )}
            </div>
            {planList.map(
                (plan: Plan, index: number) => {
                    const { finalDp, finalEmi } = divideCashBack(plan.tdp, plan.emiAmount, plan.tenure);
                    return (
                        <div key={`plan_${index}`} className="flex justify-between pb-[16px]">
                            <div>
                                <div className="text-left flex h-[17px]">
                                    <Bold10Text
                                        text={`₹${finalEmi}`}
                                        className="text-[#079854] italic leading-[12.19px]"
                                    />
                                    &nbsp;
                                    <SemiBold10Text
                                        text={` * ${plan.tenure} Months`}
                                        className="text-[#000000] leading-[12.19px]"
                                    />
                                    {plan.isZeroPercent && (
                                        <SnapBadge className="ml-[16px] rounded-[13px] h-fit px-[10px]">
                                            <Bold7Text text="0% EMI" className="text-[#FFFFFF]" />
                                        </SnapBadge>
                                    )}
                                </div>
                                <SemiBold10Text
                                    text={`(+ ₹${finalDp?.toFixed(0)} Downpayment)`}
                                    className="text-[#000000]"
                                />
                            </div>
                            <SnapPrimaryButton
                                active={true}
                                text={`Buy on ${plan.tenure} months EMI`}
                                className="font-interBold text-[10px] px-[16px] py-[10px] h-[32px] leading-[12.19px] w-[146px]"
                                onClick={(e: any) => {
                                    onSelect(
                                        plan,
                                        variableDpOptionList.filter((variableDpOption: any) => {
                                            return variableDpOption.default;
                                        })[0],
                                        true
                                    );
                                }}
                            />
                        </div>
                    );
                }
            )}
            {planList && planList.length > 0 && (
                <Regular10Text className="italic leading-[12.19px] -mt-[4px] text-[#000000]" text={`EMIs will start from ${new Date(planList[0].emiStartDate).toLocaleDateString('en-US', { month: 'short', year: "2-digit" })}`} />
            )}
        </div >
    )
}

export enum UnleashEnum {
    NEW_CHECKOUT_CASHBACK = 'New_Checkout_Cashback',
    UPI_SI_BOTTOM_OVERLAY = 'UPI_SI_Bottom_overlay',
    VARIANT_A_WITHOUTGAPS = 'VariantA_withoutgaps',
    VARIANT_B_WITHGAPS = 'VariantB_Withgaps',
    VARIANT_DEFAULT = 'Variant_default',
    REMOVE_NACH_EXP = 'RemoveNACH_Exp',
    CHECKOUT_TNC_MITC_TOGGLE = 'CHECKOUT_TNC_MITC_TOGGLE',
    CHECKOUT_TNC_TOGGLE = 'CHECKOUT_TNC_TOGGLE',
    NEW_KYC_SERVICE = 'SNAP-16348_New_Kyc_Service',
    PLAN_CHANGE_COMPONENT = 'Plan_change_component'
}

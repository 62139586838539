import { Bold18Text, Regular14Text, SnapNewLoader } from "@snapmint/ui-components"

interface PersonalLoanLoaderProps {
    title?: string
    subTitle?: string
}

const PersonalLoanLoader = ({ title = 'Just a few min..', subTitle = '' }: PersonalLoanLoaderProps) => {
    return (
        <div className="h-[calc(100vh-40px)] w-full">
            <div className="absolute left-1/2 right-0 top-1/2 bottom-0 h-fit w-fit -translate-x-1/2 -translate-y-1/2 flex flex-col gap-8 items-center">
                <Bold18Text text={title} className="text-subLine text-center" />
                <SnapNewLoader
                    loaderId={`snap_loader_${new Date().getMilliseconds()}`}
                    loop={true}
                    autoplay={true}
                    width={158}
                    height={158}
                    className=""
                />
                <Regular14Text text={subTitle} className="text-subLine text-center" />
            </div>
        </div>
    );
};

export default PersonalLoanLoader
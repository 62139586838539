import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BroadCastType } from '../../enum/broadcast-type.enum';
import { PaynimoAction } from '../../enum/paynimo.enum';
import { RedirectTypeEnum } from '../../enum/redirect-type.enum';
import { BroadCastTabController } from './broadcast-tab.controller';

const { NOT_INIT, SUCCESS, CANCELLED, ONGOING } = RedirectTypeEnum;

export const useRedirectionHelper = () => {
    const navigate = useNavigate();
    const tabRef = useRef<any>(null);

    const [childTabStatus, setChildTabStatus] = useState(NOT_INIT);
    const [digilockerStatus, setDigilockerStatus] = useState();
    const [txnId, setTxnId] = useState();

    const brodTest = useRef<any>(null);

    const startBroadCasting = () => {
        brodTest.current = BroadCastTabController.getNewBroadCast();
        brodTest.current.addEventListener('message', (e: any) => {
            if (e && e.type === BroadCastType.TabInteraction) {
                setDigilockerStatus(e.digilockerStatus);
                setTxnId(e.txnId);
                setChildTabStatus(e.status);
            }
        });
    };

    const stopBroadCasting = () => {
        if (brodTest.current) {
            brodTest.current.close();
        }
    };

    const goToTab = () => {
        tabRef.current?.focus();
    };

    useEffect(() => {
        let checkChildTabStatus: any;
        checkChildTabStatus = setInterval(() => {
            if (tabRef.current?.closed) {
                if (childTabStatus !== SUCCESS) {
                    setChildTabStatus(CANCELLED);
                }
                clearInterval(checkChildTabStatus);
            }
        }, 500);
        return () => clearInterval(checkChildTabStatus);
    }, [childTabStatus]);

    const resetRedirection = () => {
        if (tabRef.current) {
            tabRef.current.close();
        }
        stopBroadCasting();
        setChildTabStatus(NOT_INIT);
    };

    const checkRedirection = (res: any, failure_message=""): any => {
        if (res && res.redirect && res.redirect.url) {
            if (res.data && res.data.emandateData) {
                resetRedirection();
                openPaynimoTab(res.paynimoUrl, res.data.emandateData, res.redirect.type == RedirectTypeEnum.IN_IFRAME);
                return { redirect: true, newWindow: true, popUpBlocked: popUpBlocked(res.redirect.type == RedirectTypeEnum.IN_IFRAME), sameTab:  res.redirect.type == RedirectTypeEnum.IN_IFRAME };
            }

            if (res.redirect.method && res.redirect.method.toLowerCase() == 'post') {
                resetRedirection();
                formPostInNewWindow(res.redirect.url, res.redirect.params, res.redirect.type);
                return { redirect: true, newWindow: true, popUpBlocked: popUpBlocked(res.redirect.type == RedirectTypeEnum.IN_IFRAME), sameTab:  res.redirect.type == RedirectTypeEnum.IN_IFRAME };
            }
            if (res.redirect.type == RedirectTypeEnum.OUT_SIDE) {
                resetRedirection();
                openInNewWindow(res.redirect.url);
                return { redirect: true, newWindow: true, popUpBlocked: popUpBlocked(res.redirect.type == RedirectTypeEnum.IN_IFRAME), sameTab:  res.redirect.type == RedirectTypeEnum.IN_IFRAME };
            }
            if (
                (res.redirect.method && res.redirect.method.toLowerCase() == 'get') ||
                res.redirect.type == RedirectTypeEnum.IN_IFRAME
            ) {
                resetRedirection();
                window.location.href = res.redirect.url;
                return { redirect: true, newWindow: false, popUpBlocked: false, sameTab: true };
            }

            navigate(res.redirect.url, { state: { ...res, checkoutRedirection: true, failure_message: failure_message }, replace: true });
            return { redirect: true, newWindow: false, popUpBlocked: false };
        }
        return { redirect: false, newWindow: false, popUpBlocked: false };
    };

    const openPaynimoTab = (url: string, emandateData: any, sameTab: boolean) => {
        if(!sameTab) {
            setChildTabStatus(ONGOING);
            startBroadCasting();

            brodTest.current.addEventListener('message', (e: any) => {
                if (e.type == BroadCastType.Paynimo && e.action == PaynimoAction.REQUEST_MANDATE_DATA) {
                    brodTest.current.postMessage({
                        type: BroadCastType.Paynimo,
                        action: PaynimoAction.POST_MANDATE_DATA,
                        emandateData: emandateData
                    });
                }
            });

            tabRef.current = window.open(url, '_blank');
        }
    };

    const openInNewWindow = (url: string) => {
        tabRef.current = window.open(url, '_blank');
        setChildTabStatus(ONGOING);
        startBroadCasting();
    };

    const formPostInNewWindow = (url: string, params: any, type: string) => {
        var windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();

        var form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', url);
        if (type != RedirectTypeEnum.IN_IFRAME) {
            form.setAttribute('target', windowName);
        } else {
            form.setAttribute('target', '_self');
        }
        for (var i in params) {
            if (params.hasOwnProperty(i)) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        try {
            if (type != RedirectTypeEnum.IN_IFRAME) {
                tabRef.current = window.open('', windowName);
                form.target = windowName;
            }
            form.submit();
        } catch (err: any) {
            console.log('pop up blocked', err);
        }

        document.body.removeChild(form);

        setChildTabStatus(ONGOING);
        startBroadCasting();
    };

    const popUpBlocked = (sameTab: boolean) => {
        if (sameTab) {
            return false
        }
        if (!tabRef.current || tabRef.current.closed || typeof tabRef.current.closed == 'undefined') {
            return true;
        } else {
            return false;
        }
    };

    return {
        checkRedirection,
        childTabStatus,
        digilockerStatus,
        goToTab,
        resetRedirection,
        txnId
    };
};

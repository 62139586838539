//Super set of possible params
// id: 81214,
//   user_id: null,
//   merchant_id: 117,
//   campaign_product_id: null,
//   mobile: '7755058011',
//   discount: null,
//   full_name: null,
//   first_name: null,
//   last_name: null,
//   pan: null,
//   aadhaar_number: null,
//   dob: null,
//   gender: null,
//   is_active: null,
//   order_id: '16726745601990',
//   merchant_confirmation_url: 'http://localhost:3000/success|10000||',
//   merchant_failure_url: 'http://localhost:3000/failure',
//   udf1: null,
//   udf2: null,
//   udf3: null,
//   created_at: '2023-01-02T15:49:21.000Z',
//   updated_at: '2023-01-02T15:49:21.000Z',
//   merchant_user_id: null,
//   loan_application_id: null,
//   patient_name: null,
//   applicant_relationship: null,
//   disease_details: null,
//   has_claim: false,
//   disease_name: null,
//   plan_id: null,
//   subvention: null,
//   merchant_tenure_id: null,
//   product_master_id: null,
//   product_inventory_id: null,
//   selected_dp: null,
//   selected_tenure: null,
//   tenure: null,
//   papernach_allowed: true,
//   merchant_channel: 'online_mobile',
//   merchant_name: 'sss',
//   is_checkout_merchant: true,
//   is_returning_user: null,
//   is_coupon_applicable: false,
//   coupon_details: {},
//   order_value: 10000,
//   address_exists: false,
//   is_plugin_ready: false,
//   shipping_address: { address_line1: null, address_line2: null, city: null, zip: null },
//   products: [ [length]: 0 ],
//   is_stepper_flow: false

export class TransactionParamModel {
    checkoutId?: string;
    merchantId: string;
    orderId: string;
    orderValue: string;
    mobile: string;
    checkSumHash: string;
    name?: string;
    email?: string;
    merchantFailureUrl: string;
    merchantConfirmationUrl: string;
    address?: any;
    productInfo?: any;
    polling: boolean;
    txnId?: string;
    fetchType?: string;
    cartId?: string;
    token?: string;
    merchantName?: string;
    campaignProductId?: string;
    userId?: string;
    loanId?: string;
    snapWebToken?: string;
    snapPlanId?: string;
    snapFlowType?: string;
    planFlowType?: string;
    emiPlanId?: string;
    snapVariableDp?: string;
    ckType?: string;
    errMsg?: string;
    redirectUrl?: string;
    status?: string;
    isAiaFlow?: string;
    isShopifyAiaFlow?: string;
    snapAdt?: number;
    snapChd?: number;
    snapInf?: number;
    flightSku?: string;
    flightCategory?: string;
    flightUdf1?: string;
    flightName?: string;
    flightBrandName?: string;
    showMerchantHeader?: boolean;
    merchantLogo?: string;
    merchantHeaderTitle?: string;
    isCheckoutMerchant?: string;
    isSuperCheckout?: string;
    isCCBanner?: string;
    whatappAllowed?: string;
    flowType?: string;
    successPath?: string;
    failurePath?: string;
    userToken?: string;
    isPlFlow?: boolean;
    plReason?: string;

    constructor(
        merchantId: string,
        orderId: string,
        orderValue: string,
        polling: boolean,
        merchantFailureUrl: string,
        merchantConfirmationUrl: string,
        mobile: string,
        checkSumHash: string,
        checkoutId?: string,
        name?: string,
        email?: string,
        address?: any,
        productInfo?: any,
        txnId?: string,
        fetchType?: string,
        cartId?: string,
        token?: string,
        merchantName?: string,
        campaignProductId?: string,
        userId?: string,
        loanId?: string,
        snapWebToken?: string,
        snapPlanId?: string,
        snapFlowType?: string,
        planFlowType?: string,
        emiPlanId?: string,
        snapVariableDp?: string,
        ckType?: string,
        errMsg?: string,
        redirectUrl?: string,
        status?: string,
        isAiaFlow?: string,
        isShopifyAiaFlow?: string,
        snapAdt?: number,
        snapChd?: number,
        snapInf?: number,
        flightSku?: string,
        flightCategory?: string,
        flightUdf1?: string,
        flightName?: string,
        flightBrandName?: string,
        showMerchantHeader?: boolean,
        merchantLogo?: string,
        merchantHeaderTitle?: string,
        isCheckoutMerchant?: string,
        isSuperCheckout?: string,
        isCCBanner?: string,
        whatappAllowed?: string,
        flowType?: string,
        successPath?: string,
        failurePath?: string,
        userToken?: string,
        isPlFlow?: boolean,
        plReason?: string
    ) {
        this.checkoutId = checkoutId;
        this.merchantId = merchantId;
        this.orderId = orderId;
        this.orderValue = orderValue;
        this.mobile = mobile;
        this.checkSumHash = checkSumHash;
        this.name = name;
        this.email = email;
        this.merchantFailureUrl = merchantFailureUrl;
        this.merchantConfirmationUrl = merchantConfirmationUrl;
        this.address = address;
        this.productInfo = productInfo;
        this.polling = polling;
        this.txnId = txnId;
        this.fetchType = fetchType;
        this.cartId = cartId;
        this.token = token;
        this.merchantName = merchantName;
        this.campaignProductId = campaignProductId;
        this.userId = userId;
        this.loanId = loanId;
        this.snapWebToken = snapWebToken;
        this.snapPlanId = snapPlanId;
        this.snapFlowType = snapFlowType;
        this.planFlowType = planFlowType;
        this.emiPlanId = emiPlanId;
        this.snapVariableDp = snapVariableDp;
        this.ckType = ckType;
        this.errMsg = errMsg;
        this.redirectUrl = redirectUrl;
        this.status = status;
        this.isAiaFlow = isAiaFlow;
        this.isShopifyAiaFlow = isShopifyAiaFlow;
        this.snapAdt = snapAdt;
        this.snapChd = snapChd;
        this.snapInf = snapInf;
        this.flightSku = flightSku;
        this.flightCategory = flightCategory;
        this.flightUdf1 = flightUdf1;
        this.flightName = flightName;
        this.flightBrandName = flightBrandName;
        this.showMerchantHeader = showMerchantHeader;
        this.merchantLogo = merchantLogo;
        this.merchantHeaderTitle = merchantHeaderTitle;
        this.isCheckoutMerchant = isCheckoutMerchant;
        this.isSuperCheckout = isSuperCheckout;
        this.isCCBanner = isCCBanner;
        this.whatappAllowed = whatappAllowed;
        this.flowType = flowType;
        this.successPath = successPath;
        this.failurePath = failurePath;
        this.userToken = userToken;
        this.isPlFlow = isPlFlow;
        this.plReason = plReason
    }
}

import posthog from 'posthog-js';
import { EventName } from './event-name.enum';
import { gtm } from '../utils/gtm';

const userPropertiesAll:any={};
export const captureEvent = (eventName: EventName|string, userproperties: any = {}, hotjar: boolean = false , gtmEvent: boolean = false , gtmProperties: Record<string , any> = {}) => {
    try {
        posthog.capture(eventName, userproperties);
        if (hotjar) {
            getAllProperties(userproperties);
            postEventInHotJar(eventName, userproperties);
            if (userproperties?.user_id) {
                identifyUserInHotJar(userproperties?.user_id, userPropertiesAll);
            }
        }
        if(gtmEvent) {
            gtm.captureGtmEvents(eventName , gtmProperties )
        }
    } catch (err) {
        console.log(err);
    }
};

export const captureHotJarEvent = (eventName: EventName | string, userproperties: any = {}) => {
    try {
        getAllProperties(userproperties);
        postEventInHotJar(eventName, userproperties);
        if (userproperties?.user_id) {
            identifyUserInHotJar(userproperties?.user_id, userPropertiesAll);
        }
    } catch (err) {
        console.log(err);
    }
};

export const identifyUserInHotJar = (userId: string, userproperties: any = {}) => {
    try {
        const _window = window as any;
        _window.hj('identify', userId, userproperties);
    } catch (err) {
        console.log(err);
    }
};

export const postEventInHotJar = (eventName: EventName | string, userproperties: any = {}) => {
    try {
        const _window = window as any;
        _window.hj('event', eventName, userproperties);
    } catch (err) {
        console.log(err);
    }
};

const getAllProperties = (userproperties: any = {}) => {
    if (userproperties?.user_id) {
        userPropertiesAll.user_id = userproperties?.user_id;
    }
    if (userproperties?.merchant_id) {
        userPropertiesAll.merchant_id = userproperties?.merchant_id;
    }
    if (userproperties?.merchant_name) {
        userPropertiesAll.merchant_name = userproperties?.merchant_name;
    }
    if (userproperties?.cart_id) {
        userPropertiesAll.cart_id = userproperties?.cart_id;
    }
    if (userproperties?.mobile_no) {
        userPropertiesAll.mobile_no = userproperties?.mobile_no;
    }
    if (userproperties?.user_channel) {
        userPropertiesAll.user_channel = userproperties?.user_channel;
    }
    if (userproperties?.loan_id) {
        userPropertiesAll.loan_id = userproperties?.loan_id;
    }
    if (userproperties?.order_value) {
        userPropertiesAll.order_value = userproperties?.order_value;
    }
};

import { useRef, useEffect } from "react";

interface IUseInterval {
    (callback: () => void, start: boolean, interval: number): void;
}

export const useInterval: IUseInterval = (callback, start, interval) => {
    const savedCallback = useRef<(() => void) | null>(null);
    // After every render, save the latest callback into our ref.
    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {

        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (start) {
            let id = setInterval(tick, interval);
            return () => clearInterval(id);
        }
    }, [start, interval]);
};

import { useParams } from "react-router-dom";
import { TransactionParamModel } from "../../model/transaction-param.model";
import { PersonalLoan } from "../../enum/enum"

export const useUrlParamParser = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let { id } = useParams();

    let _polling = false
    if (params.get("is_setu_loader") == 'true') {
        _polling = true
    }

    let _id = params.get("checkoutId") as string
    if (id) {
        _id = id
    }

    let _merchantName = params.get("merchant_name") as string
    if (!(_merchantName && _merchantName.length > 0)) {
        _merchantName = params.get("merchantName") as string
    }
    const merchantId = (params.get('merchantId') || params.get('merchant_id')) as string;
    const showMerchantHeader = params.get('wa') ? JSON.parse(params.get('wa') as string) : false;
    const merchantLogo = decodeURIComponent(params.get('img') as string ?? "");
    const merchantHeaderTitle = decodeURIComponent(params.get('title') as string ?? "");
    const userToken = params.get('user_token') as string
    const flowType = params.get('flow_type') as string;
    const succesPath = params.get('success_path') as string
    const failurePath = params.get('failure_path') as string
    const ckType = (params.get('ck_type') || params.get('ckType')) as string;
    const plReason = params.get('pl_reason') ?? ""
    const transactionParamModel = new TransactionParamModel(
        merchantId,
        params.get("orderId") as string,
        params.get("orderValue") as string,
        _polling,
        params.get("merchantFailureUrl") as string,
        params.get("merchantConfirmationUrl") as string,
        params.get("mobile") as string,
        params.get("checkSumHash") as string,
        _id,
        params.get("name") as string,
        params.get("email") as string,
        params.get("address") as string,
        params.get("productInfo") as string,
        params.get("transaction_id") as string,
        params.get("fetchType") as string,
        params.get("id") as string,
        params.get("token") as string,
        _merchantName,
        params.get("campaign_product_id") as string,
        params.get("userId") as string,
        params.get("loanId") as string,
        params.get("snap_web_token") as string,
        params.get("snap_plan_id") as string,
        params.get("snap_flow_type") as string,
        params.get("plan_flow_type") as string,
        params.get("emiPlanId") as string,
        params.get("snap_variable_dp") as string,
        ckType,
        params.get("message") as string,
        params.get("redirect_url") as string,
        params.get("status") as string,
        params.get('isAiaFlow') as string,
        params.get('isShopifyAiaFlow') as string,
        Number(params.get('snap_adt')),
        Number(params.get('snap_chd')),
        Number(params.get('snap_inf')),
        params.get('flight_sku') as string,
        params.get('flight_category') as string,
        params.get('flight_udf1') as string,
        params.get('flight_name') as string,
        params.get('flight_brand_name') as string,
        showMerchantHeader,
        merchantLogo,
        merchantHeaderTitle,
        params.get('isCheckoutMerchant') as string,
        params.get('isSuperCheckout') as string,
        params.get('cc_banner') as string,
        params.get('whatapp_allowed') as string,
        flowType,
        succesPath,
        failurePath,
        userToken,
        ckType === PersonalLoan.PERSONAL_LOAN,
        plReason
    )

    return transactionParamModel
}